import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../AdminLoginLayout";
import NewOrders from "../../pages/AdminDashboard/NewOrders";
import PriorityOrders from "../../pages/AdminDashboard/PriorityOrders";
import GatewaySetting from "../../pages/GatewaySetting";
import ChangePassword from "../../pages/AdminDashboard/ChangePassword/ChangePassword";
import ManageTeam from "../../pages/AdminDashboard/ManageTeam";
import PendingOrders from "../../pages/AdminDashboard/PendingOrders/PendingOrders";
import CompletedOrders from "../../pages/AdminDashboard/CompletedOrders/CompletedOrders";
import DeletedOrders from "../../pages/AdminDashboard/DeletedOrders/DeletedOrders";
import AwaitingResponse from "../../pages/AdminDashboard/AwaitingResponse/AwaitingResponse";
import AwaitingGovt from "../../pages/AdminDashboard/AwaitingGovt/AwaitingGovt";
import CustomerCalled from "../../pages/AdminDashboard/CustomerCalled/CustomerCalled";
import OrderHistory from "../../pages/AdminDashboard/OrderHistory/OrderHistory";
import ContactCustomer from "../../pages/AdminDashboard/ContactCustomer/ContactCustomer";
import RefundOrders from "../../pages/AdminDashboard/RefundOrders/RefundOrders";
import ViewOrders from "../../components/ViewOrders/ViewOrders";
import FutureOrders from "../../pages/AdminDashboard/FutureOrders";
import CountryOrderList from "../../pages/AdminDashboard/CountryOrderList";
import ProcessedByOrderList from "../../pages/ProcessedByOrderList/ProcessedByOrderList";
import MyOrders from "../../pages/AdminDashboard/MyOrders/MyOrders";
import ChargeBack from "../../pages/AdminDashboard/ChargeBack";

const Main = (props) => {
  return (
    <main>
      <Routes>
        <Route path="/admin" element={<Login />} />
        <Route path="/" element={<NewOrders />} />
        <Route path="/recent-order" element={<NewOrders />} />
        <Route path="/my-order" element={<MyOrders />} />
        <Route path="/future-order" element={<FutureOrders />} />
        <Route path="/priority-order" element={<PriorityOrders />} />
        <Route path="/pending-order" element={<PendingOrders />} />
        <Route path="/completed-order" element={<CompletedOrders />} />
        <Route path="/deleted-order" element={<DeletedOrders />} />
        <Route path="/awaiting-response" element={<AwaitingResponse />} />
        <Route path="/awaiting-govt" element={<AwaitingGovt />} />
        <Route path="/called" element={<CustomerCalled />} />
        <Route path="/order-history" element={<OrderHistory />} />
        <Route path="/contact-customer" element={<ContactCustomer />} />
        <Route path="/refund-order" element={<RefundOrders />} />
        <Route path="/chargeback" element={<ChargeBack />} />
        <Route path="/country-order-list" element={<CountryOrderList />} />
        <Route path="/gateway-setting" element={<GatewaySetting />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/manage-team" element={<ManageTeam />} />
        <Route path="/manage-team/:teamMemberId" element={<ManageTeam />} />
        <Route path="/processed-order" element={<ProcessedByOrderList/>} />
        <Route path="/view-order/:orderId" element={<ViewOrders />} />
      </Routes>
    </main>
  );
};

export default Main;
