import React from 'react'
import LoaderSvg from "../../assets/images/loader.svg"

const Loader = () => {
    return (
        <>
            <img src={LoaderSvg} alt="loader" width="16%" height={24} style={{display:"inline",width: "20%"}} /> Loading...
        </>
    )
}

export default Loader