import React, { useEffect, useState } from "react";
import { StyledContainer, StyledPageTitle, StyledOrderBlock } from "./style";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getOrdersList } from "../../../features/order/orderSlice";
import ReactDataTable from "../../../components/ReactDataTable";
import { toast } from "react-toastify";

const ChargeBack = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const chargeBackList = useSelector((state) => state?.order?.ordersList);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);

    useEffect(() => {
        dispatch(getOrdersList({
            orderName: "chargeBack",
            page: page,
            perPage: limit,
        })).unwrap().then((res) => {
            if (res.status === 401) {
                toast.error(`${res?.message}`, { className: "toast-message" });
                localStorage.removeItem("user");
                navigate("/");
            }
        });
        document.title = "CANADA eTA";
    }, [dispatch, navigate, page, limit]);

    const handleClick = () => {
        window.location.reload();
    };

    const tableData = chargeBackList;
    return (
        <>
            <StyledContainer>
                <StyledPageTitle>
                    <h1>Charge Back</h1>
                    <button type="button" className="btn" style={{background:"green",color:"#fff"}} onClick={handleClick}>Refresh</button>
                </StyledPageTitle>
                <form>
                    <StyledOrderBlock>
                        <div className="table-block table-responsive">
                            <ReactDataTable
                                data={tableData}
                                setPage={setPage}
                                setLimit={setLimit}
                                orderName="chargeBack"
                                // buttonWrapper="newOrder"
                            />
                        </div>
                    </StyledOrderBlock>
                </form>
            </StyledContainer>
        </>
    );
}

export default ChargeBack;