import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { StyledDownload } from "./style";
import {
  downloadPassportDocPdf,
  getDownloadHistory,
} from "../../features/visitor/visitorSlice";
import { decryptVal } from "../../utils/utility";

const DownloadHistory = () => {
  const dispatch = useDispatch();
  const { orderId } = useParams();
  const [downloadLink, setDownloadLink] = useState(null);

  const downloadFilePath = useSelector(
    (state) => state?.visitor?.downloadFilePath
  );
  const downloadHistoryList = useSelector(
    (state) => state?.visitor?.downloadHistoryList
  );
  useEffect(() => {
    let data = { orderId: decryptVal(orderId) };
    dispatch(getDownloadHistory(data));
    document.title = "CANADA eTA";
  }, [dispatch, orderId]);
  
  const handleDownloadList = async (item) => {
    try {
      const response = await dispatch(
        downloadPassportDocPdf({
          orderId: decryptVal(orderId),
          customername: item,
          file1: downloadFilePath?.file1,
        })
      ).unwrap();

      console.log("Response ", response);

      // Check if the API call was successful
      if (response?.status === 1) {
        // Refresh download history after successful API call
        const data = { orderId: decryptVal(orderId) };
        dispatch(getDownloadHistory(data));

        // Set the download link dynamically
        const fileName = downloadFilePath?.file1; // Use the actual file name from the API
        const link = `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_PUBLIC_UPLOAD_PATH
          }docs/${decryptVal(orderId)}_${fileName}`;
        setDownloadLink(link);

        // Delay the download by 1 second
        setTimeout(() => {
          const anchor = document.createElement("a");
          anchor.href = link;
          anchor.download = fileName; // Set the download attribute to the actual file name
          anchor.click();
        }, 1000); // 1-second delay
      } else {
        console.error(
          "Download API response indicates failure:",
          response?.message
        );
      }
    } catch (error) {
      console.error("Error during download:", error.message);
    }
  };

  return (
    <StyledDownload>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <p>
              <b>
                Your application for an Electronic Travel
                Authorization (eTA) has been
                completed successfully.
              </b>
            </p>
          </div>
          <div className="col-md-12">
            <p>
              <b>Thank you for using our services.</b>
            </p>
            <form className="form-inline">
              <p>
                You can download and save your Electronic Travel
                Authorization (eTA) by
                clicking the button below.
                <br />
              </p>
              <div className="wrapper" style={{ textAlign: "center" }}>
                <button
                  type="button"
                  name="doc1"
                  className="btn btn-primary mb-2"
                  id="download_btn"
                  onClick={() => handleDownloadList("customer")}
                  disabled={!downloadFilePath?.file1}
                >
                  Download Now{" "}
                  <i className="fa fa-download" aria-hidden="true"></i>
                </button>
                <br />
                <br />
              </div>
            </form>
          </div>
          <div className="col-md-12 Responsive-table">
            <br />
            <h3>Downloaded History</h3>
            <table className="table table-striped table-bordered myTable showhistory">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Order ID</th>
                  <th>Date &amp; Time (EST)</th>
                  <th>Device IP Address</th>
                  <th>Device</th>
                  <th>Device Type</th>
                  <th>Browser</th>
                  <th>OS</th>
                  <th>Device Location</th>
                </tr>
              </thead>
              {downloadHistoryList?.length > 0 &&
                downloadHistoryList.map((item, index) => (
                  <tbody key={index}>
                    <tr>
                      <td>{index + 1}</td>
                      <td>{item?.order_id}</td>
                      <td>{item?.create_ts}</td>
                      <td>{item?.ip?.replace(/^::ffff:/, "") || ""}</td>
                      <td>{item?.browser?.split("-")?.[2] || ""}</td>
                      <td>{`${item?.browser?.split("-")?.[2] || ""} ${item?.browser?.split("-")?.[3]?.replace("Unknown", "") || ""}`}</td>
                      <td>{item?.os?.replace("Unknown", "") || ""}</td>
                      <td>{item?.timezone}</td>
                    </tr>
                  </tbody>
                ))}
            </table>
          </div>
        </div>
      </div>
    </StyledDownload>
  );
};

export default DownloadHistory;
