import React, { useEffect, useState } from 'react';
import NewOrderIcon from '../../../assets/images/icon1.svg';
import PendingOrderIcon from '../../../assets/images/icon2.svg';
import DeletedIcon from '../../../assets/images/icon3.svg';
import AwaitingIcon from '../../../assets/images/icon4.svg';
import OrderHistoryIcon from '../../../assets/images/icon5.svg';
import ContactCustomerIcon from '../../../assets/images/icon6.svg';
import ManageTeamIcon from '../../../assets/images/icon7.svg';
import GateWayIcon from '../../../assets/images/icon8.svg';
import ChangePasswordIcon from '../../../assets/images/icon10.svg';
import LogoutIcon from '../../../assets/images/icon11.svg';
import ContactCustomer from "../../../assets/images/contact_customer.svg";
import CompletedOrderIcon from '../../../assets/images/checklist.png';
import { StyledSidebar, StyledAdminMenu } from './style';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { cleanOrdersList, getFolderCount, toggleSidebar } from '../../../features/order/orderSlice';
import { logout } from '../../../features/user/authSlice';
import { FaTimes } from 'react-icons/fa';
import { jwtDecode } from 'jwt-decode';

const AdminSidebar = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = localStorage.getItem('user');
    const count = useSelector((state) => state?.order?.count);
    const showSideBar = useSelector((state) => state?.order?.showSideBar);
    const [isActive, setIsActive] = useState(false);
    const location = useLocation();
    let userType;
    let userName;
    if (token !== null) {
        const decodedToken = jwtDecode(token);
        userType = decodedToken?.user?.type;
        userName = decodedToken?.user?.name;
    }
    useEffect(() => {
        dispatch(getFolderCount(userName));
        dispatch(cleanOrdersList());
    }, [location?.pathname]);

    const handleLogout = (e) => {
        e.preventDefault();
        localStorage.removeItem('user');
        dispatch(logout());
        navigate('/');
    };


    console.log(userType == "Night Staff", "userType")
    return (
        <StyledSidebar>
            <div className={showSideBar ? 'sidebar open' : 'sidebar'}>
                <StyledAdminMenu>
                    <button type='button' onClick={() => { dispatch(toggleSidebar); }} className='close-menu'>
                        <FaTimes />
                    </button>
                    <ul>
                        <li className={isActive === 'awaiting' ? 'active' : null}>
                            <NavLink to='/admin/awaiting-response' onClick={() => setIsActive('awaiting')}>
                                <span className='side-icon'>
                                    <img src={AwaitingIcon} alt='AwaitingIcon' />
                                </span>
                                Awaiting Customer <span className='count'>{count?.awaitingOrdersCount}</span>
                            </NavLink>
                        </li>
                        <li className={isActive === 'awaitinggovt' ? 'active' : null}>
                            <NavLink to='/admin/awaiting-govt' onClick={() => setIsActive('awaitinggovt')}>
                                <span className='side-icon'>
                                    <img src={AwaitingIcon} alt='AwaitingIcon' />
                                </span>
                                Awaiting Govt <span className='count'>{count?.awaitingGovtCount}</span>
                            </NavLink>
                        </li>
                        <li className={isActive === 'customer' ? 'active' : null}>
                            <NavLink to='/admin/called' onClick={() => setIsActive('customer')}>
                                <span className='side-icon'>
                                    <img src={AwaitingIcon} alt='AwaitingIcon' />
                                </span>
                                Customer Called <span className='count'>{count?.customerCalledCount}</span>
                            </NavLink>
                        </li>
                        <li className={isActive === 'customercontact' ? 'active' : null}>
                            <NavLink to='/admin/contact-customer' onClick={() => setIsActive('customercontact')}>
                                <span className='side-icon'>
                                    <img src={ContactCustomerIcon} alt='AwaitingIcon' />
                                </span>
                                Contact Customer  <span className='count'>{count?.contactCustomerOrdersCount}</span>
                            </NavLink>
                        </li>
                        {userType !== 'Telecaller' && (
                            <li className={isActive === 'allorder' ? 'active' : null}>
                                <NavLink to='/admin/order-history' onClick={() => setIsActive('allorder')}>
                                    <span className='side-icon'>
                                        <img src={OrderHistoryIcon} alt='OrderHistoryIcon' />
                                    </span>
                                    Order History <span className='count'>{count?.allOrdersCount}</span>
                                </NavLink>
                            </li>
                        )}
                        {/* <li className={isActive === 'futureOrder' ? 'active' : null}>
                            <NavLink to='/admin/future-order' onClick={() => setIsActive('futureOrder')}>
                                <span className='side-icon'>
                                    <img src={AwaitingIcon} alt='Future Order' />
                                </span>
                                Future Orders <span className='count'>{count?.futureOrdersCount}</span>
                            </NavLink>
                        </li> */}
                        <li className={isActive === "archive" ? "active" : null}>

                            <NavLink
                                to="/admin/deleted-order"
                                onClick={() => setIsActive("deleted-order")}
                            >
                                <span className="side-icon">
                                    <img src={DeletedIcon} alt="DeletedIcon" />
                                </span>
                                Deleted Orders{" "}
                                <span className="count">{count?.deletedOrdersCount}</span>
                            </NavLink>
                        </li>
                        <li className={isActive === 'refund' ? 'active' : null}>
                            <NavLink to='/admin/refund-order' onClick={() => setIsActive('refund')}>
                                <span className='side-icon'>
                                    <img src={NewOrderIcon} alt='NewOrderIcon' />
                                </span>
                                Refunds <span className='count'>{count?.refundOrdersCount}</span>
                            </NavLink>
                        </li>
                        <li className={isActive === 'chargeback' ? 'active' : null}>
                            <NavLink to='/admin/chargeback' onClick={() => setIsActive('chargeback')}>
                                <span className='side-icon'>
                                    <img src={NewOrderIcon} alt='NewOrderIcon' />
                                </span>
                                Charge Back <span className='count'>{count?.chargeBackCount}</span>
                            </NavLink>
                        </li>
                        <li className={isActive === 'countryOrderCountList' ? 'active' : null}>
                            <NavLink to='/admin/country-order-list' onClick={() => setIsActive('countryOrderCountList')}>
                                <span className='side-icon'>
                                    <img src={NewOrderIcon} alt='NewOrderIcon' />
                                </span>
                                Country Order List {" "}
                            </NavLink>
                        </li>
                        {userType != "Night Staff" && <> <li className={isActive === 'manage-team' ? 'active' : null}>
                            <NavLink to='/admin/manage-team' onClick={() => setIsActive('manage-team')}>
                                <span className='side-icon'>
                                    <img src={ManageTeamIcon} alt='ManageTeamIcon' />
                                </span>
                                Manage Team
                            </NavLink>
                        </li>
                            {(userType == "Admin") && (
                                <li className={isActive === 'countryOrderCountList' ? 'active' : null}>
                                    <NavLink to='/admin/processed-order' onClick={() => setIsActive('countryOrderCountList')}>
                                        <span className='side-icon'>
                                            <img src={NewOrderIcon} alt='NewOrderIcon' />
                                        </span>
                                        Order Processed {" "}
                                    </NavLink>
                                </li>
                            )}

                            <li className={isActive === 'setting' ? 'active' : null}>
                                <NavLink to='/admin/gateway-setting' onClick={() => setIsActive('setting')}>
                                    <span className='side-icon'>
                                        <img src={GateWayIcon} alt='GateWayIcon' />
                                    </span>
                                    Gateway Setting
                                </NavLink>
                            </li>
                            <li className={isActive === 'change-password' ? 'active' : null}>
                                <NavLink to='/admin/change-password' onClick={() => setIsActive('change-password')}>
                                    <span className='side-icon'>
                                        <img src={ChangePasswordIcon} alt='ChangePasswordIcon' />
                                    </span>
                                    Change Password
                                </NavLink>
                            </li></>}

                        <li>
                            <NavLink to='/logout' onClick={handleLogout}>
                                <span className='side-icon'>
                                    <img src={LogoutIcon} alt='LogoutIcon' />
                                </span>
                                Logout
                            </NavLink>
                        </li>
                    </ul>
                </StyledAdminMenu>
            </div>
        </StyledSidebar>
    );
};

export default AdminSidebar;